// import axios from 'axios';

import axios from "axios";

const default_state = {
  accessToken: null,
  idToken: null,
  expiredAt: null,
  isLogin: false,
  userName: null,
  uid: null,
  email: null,
  user: null,
  expireDurationMinutes: 30,
  autoLogoutFlg: true,
  purchased_materials: [],
  status: null
}


const state = default_state

const mutations = {
  UPDATE_ID_TOKEN(state, idToken) {
    state.idToken = idToken
  },
  UPDATE_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken
  },
  UPDATE_USER(state, user) {
    state.user = user;
    state.userName = user.name;
    state.uid = user.sub;
    state.email = user.email;
    state.isLogin = true;
    state.purchased_materials = user.purchased_materials;
    state.status = user.status;
  },
  UPDATE_EXPIRED_AT(state, timestamp) {
    state.expiredAt = timestamp;
  },
  UPDATE_EXPIRE_DURATION_MINUTES(state, duration) {
    state.expireDurationMinutes = duration;
  },
  UPDATE_AUTO_LOGOUT_FLG(state, flg) {
    state.autoLogoutFlg = flg;
  },
  RESET_AUTH(state) {
    Object.assign(state, default_state)
  },
};

const actions = {
  async setAuth({ commit, getters }, auth) {
    const idToken = await auth.getIdTokenClaims();
    commit('UPDATE_ID_TOKEN', idToken.__raw);
    commit('UPDATE_ACCESS_TOKEN', idToken);
    const userInfo = await axios.get('/api/v1/users/info')
    const { status, purchased_materials } = userInfo.data

    if (status === 'expired') {
      commit('RESET_AUTH');
      const logoutUrl = window.location.origin + '?expired=true';
      auth.logout({ returnTo: logoutUrl});
    }

    commit('UPDATE_USER', { ...auth.user, status, purchased_materials });
    if (getters.expiredAt === null) {
      // 初回ログイン時expiredAtが設定されていない場合のみに初期値を設定
      commit('UPDATE_EXPIRED_AT', Date.now() + 1000 * 60 * state.expireDurationMinutes);
    }
  },
  updateExpiredAt({ commit, getters }) {
    const timestamp = Date.now() + 1000 * 60 * getters.expireDurationMinutes; // 30分後
    commit('UPDATE_EXPIRED_AT', timestamp);
  },
  updateExpiredDurationMinutes({ commit }, duration) {
    commit('UPDATE_EXPIRE_DURATION_MINUTES', duration);
  },
  updateAutoLogoutFlg({ commit }, flg) {
    commit('UPDATE_AUTO_LOGOUT_FLG', flg);
  },
  logout({ commit }, auth) {
    auth.logout({ returnTo: window.location.origin });
    commit('RESET_AUTH');
    commit('RESET_QUESTIONS');
  }
};

const getters = {
  userName: state => state.userName,
  email: state => state.email,
  errorMessages: state => state.errorMessages,
  expiredAt: state => state.expiredAt,
  isLogin: state => state.isLogin,
  autoLogoutFlg: state => state.autoLogoutFlg,
  expireDurationMinutes: state => state.expireDurationMinutes,
};

const usersModule = {
  state,
  mutations,
  actions,
  getters
};

export default usersModule;
