import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import questionsList from "./modules/questions";
import users from "./modules/users"


Vue.use(Vuex);

const key = process.env.VUE_APP_CPA_MODE === '1' ? 'cpa' : 'cpa-kakomon';

export const localStorageKey = key

export default new Vuex.Store({
  modules: {
    questionsList,
    users,
  },
  plugins: [createPersistedState(
      {
        key: key,
        path: ['users', 'questions'],
        storage: window.localStorage
      }
  )],
})
