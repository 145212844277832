<template>
  <div>
    <cpa-top v-if="showCpaLoginPage" />
    <base-layout v-else />
  </div>
</template>

<script>
import BaseLayout from "./components/layout/BaseLayout";
import CpaTop from "./components/Top/CpaTop.vue";
import axios from "axios";

export default {
  components: { BaseLayout, CpaTop },
  computed: {
    showCpaLoginPage() {
      return this.$cpaMode && !this.$auth.isAuthenticated && !this.$auth.loading;
    },
    isAuthenticated() {
      return this.$auth.isAuthenticated
    },
  },
  watch: {
    isAuthenticated() {
      this.$store.dispatch('setAuth', this.$auth)
    }
  },
  mounted() {
    axios.get('/api/v1/ping')
    setInterval(() => {
      const exp = this.$store.state.users?.accessToken?.exp
      const tokenExpired = exp < Date.now() / 1000
      if (tokenExpired) {
        this.$auth.getTokenSilently().then(() => {
          this.$store.dispatch('setAuth', this.$auth)
        }).catch(() => {
          // this.$auth.logout()
        })
      }
    }, 1000 * 60 * 5)
  },
};
</script>


