import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import { sync } from 'vuex-router-sync'
import authConfig from "../auth_config.json";
import { Auth0Plugin } from "./auth";
import VueGtm from "vue-gtm"
import './registerServiceWorker'
import * as Sentry from "@sentry/vue";

const gtmContainerId = process.env.VUE_APP_CPA_MODE === '1' ? 'GTM-MCDRCZ7' : 'GTM-MS7HPTV'
Vue.use(VueGtm, {
      id: gtmContainerId,
      defer: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    }
);


let environment 
if (process.env.VUE_APP_STAGING_MODE === '1') {
  environment = 'staging'
  } else if (process.env.NODE_ENV === 'development') {
  environment = 'development'
} else {
    environment = 'production'
  }

const selectConfig = () => {
  if (environment=== 'development') {
    return authConfig["cpa-web-development"]
  } else if (environment === 'staging') {
    return authConfig["cpa-web-staging"]
  } else {
    return authConfig["cpa-web-production"]
  }
};

const auth_config = selectConfig();
const { domain, clientId, ui_locales } = auth_config;

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  ui_locales,
  useRefreshTokens: true,
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
  }
});

Vue.config.productionTip = false;
sync(store, router);

Vue.prototype.$cpaMode = process.env.VUE_APP_CPA_MODE === '1';


Sentry.init({
  Vue,
  dsn: "https://fbc53d4391d441cba22fc17e2e14346a@o1255983.ingest.sentry.io/4504565165195264",
  environment: environment,
});


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

