<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <v-card>
      <v-card-title class="grey white--text">
        お知らせ・更新情報
      </v-card-title>
      <div
        v-for="(news, i) in newsList"
        :key="i"
      >
        <v-list-item>
          <v-list-item-content>
            <v-row class="no-gutters flex-nowrap pb-1">
              <div class="flex flex-col text-center justify-space-between">
                <v-icon>mdi-update</v-icon>
                <div class="small-date-text">
                  {{ news.date }}
                </div>
              </div>
              <v-list-item-title class="text-wrap pl-2">
                {{ news.title }}
              </v-list-item-title>
            </v-row>
            <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
            <div
              class="caption description"
              v-html="news.description"
            />
            <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
          </v-list-item-content>
        </v-list-item>
        <v-divider v-show="i < newsList.length - 1" />
      </div>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          small="small"
          text="text"
          @click="dialog = false"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "CpaWhatsNew",
  props: {
    openWhatsNew: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dialog: false,
      newsList: [
        {
          title: '2025年目標_財務会計論スマコア追加のお知らせ',
          description: `
2025年目標_短答式_財務会計論にスマコア（スマートコアチェック）を追加いたしました。
初学本科生にはコンパクトサマリーに収録されているスマコアを、上級本科生には短答対策講義レジュメの問題編に収録されているスマコア＋を追加しておりますので、是非ご活用ください。
`,
          date: '2024/08',
        },
        {
          title: '論文式_経営学、財務会計論（理論）の追加・変更のお知らせ',
          description: `
2024年合格目標の論文式_経営学に、速習講義の追加論点レジュメの問題を追加いたしました。
論述、キーワードのカテゴリ選択欄に「追加論点」を追加しておりますので、こちらからご利用いただけます。

また、2024年合格目標の論文式_財務会計論の第22章に、改正論点（中間財務諸表）を反映いたしました。
論文対策集の差替版に基づいて文言変更、問題削除を反映しておりますので、ご確認ください。
`,
        date: '2024/07',
      },
        {
          title: '2025年目標_監査論追加のお知らせ（短答対策問題集２の追加）',
          description: `
2025年目標_短答式_監査論に短答対策問題集２の内容を追加いたしました。
2024年目標_短答式_監査論には改正が反映されていない問題が含まれておりますので、2025年目標の問題をご利用いただくことを推奨いたします。
なお、2024年目標_短答式_監査論のうち、改正により内容が大きく変わる問題が含まれる節には、タイトルに（改正未反映）と付記しております。
`,
        date: '2024/07',
      },
        {
          title: '2025年目標_監査論追加のお知らせ',
          description: `
 2025年目標_監査論
の短答対策問題集を追加いたしました。
（※）短答対策問題集１の内容を追加しております。２の内容は完成次第，随時配信予定となります。
`,
        date: '2024/06',
      },
        {
          title: '2025/2026年目標_管理会計論、2025年目標_企業法追加のお知らせ',
          description: `
2025/2026年目標_管理会計論
2025年目標_企業法
の短答対策問題集を追加いたしました。
カテゴリ選択時に，2025年目標（2025/2026年目標）を選択することで表示されます。
（※）2025年目標_監査論は完成次第，随時配信予定となります。
`,
        date: '2024/03',
      },
        {
          title: '論文式_監査論追加のお知らせ',
          description: `
2024年合格目標の問題集に、論文式_監査論を追加いたしました。
論文式_監査論は、監査論論文対策集２の問題から作成しておりますので、是非ご活用ください。

なお、Web問題集では監査論論文対策集２における暗記優先度★★★～★を以下のとおり設定しておりますので、ご留意ください。
★★★　→　A
★★　　→　B+
★　　　→　B
（本改修に伴い、重要度選択にB+を追加しております）
`,
        date: '2024/03',
      },
      {
        title: '論文式_財務会計論（理論）、経営学追加のお知らせ',
        description: `
2024年合格目標の問題集に、論文式_財務会計論と経営学を追加いたしました。
論文式_財務会計論は財務会計論（理論）の論文対策集、経営学は速習講義レジュメに掲載の問題から作成しておりますので、是非ご活用ください。

また、本改修に伴い、カテゴリ選択画面において「短答式」「論文式」の選択欄を追加しております。
論文式_財務会計論は「論文式」のカテゴリ内にございます「財務会計論」を選択いただくことで解くことができます。
`,
        date: '2024/03',
      },
      {
        title: '改正論点反映のお知らせ',
        description: `
2024年合格目標の企業法に、2024年5月短答向けの改正論点を反映致しました。
反映に伴い、今までの解答履歴・出題除外設定の削除、初回正答率のリセットが行われておりますので、予めご了承ください。

また、2023年合格目標の企業法には当該改正論点の反映が行われておりませんので、
2024年5月短答に向けて学習される方は、2024年合格目標の問題集をご利用ください。
`,
          date: '2024/03',
        },
        {
          title: '出題除外機能追加のお知らせ',
          description: `
この度、Web短答問題集に出題除外機能を追加いたしましたので、ご案内いたします。

１．出題除外機能の概要
出題除外機能とは、確実に解ける問題等の「もう解かなくてもいい問題」を登録することで、該当の問題を省いて出題する機能です。
これにより，解けなかった問題や、解けたものの曖昧だった問題等、継続して解きたい問題に絞って問題を解くことができます。

２．出題除外の登録方法・解除方法
問題画面に出題除外登録のアイコン（禁止マークのアイコンです）を追加しましたので、こちらをタップすることで登録できます。
また、出題除外登録されている問題について、アイコンを再度タップすることで登録解除ができます。

３．その他の機能
問題選択画面のその他のオプションで「出題除外した問題も含めて出題」をチェックすることで、出題除外した問題も含めてすべて解くことができます。
また、問題選択画面のその他のオプションで「出題除外した問題のみ出題」をチェックすることで、出題除外した問題のみ解く（確認する）ことができます。

操作方法等の詳細は、ユーザガイドの「5-4 問題を解くー出題除外を登録する」よりご確認いただけます。
`,
          date: '2024/01',
        },
        {
          title: '機能追加修正のお知らせ',
          description: `
この度、Web短答問題集につきまして以下の機能追加・修正を行いましたので、ご案内いたします。

１．正答率での絞り込み機能を追加しました
一問一答問題選択 > その他オプションで「正答率XX％以上の問題を出題」を追加しました。これにより、選択した問題について、正答率が60%,65%,70%,75%以上の問題で絞り込んで解くことが出来ます。正答率情報の詳細は<a target="_blank" rel="noopener" href="https://drive.google.com/file/d/1upZPruPTcfR3RxgvcHgGmZn8-OtbQBuk/view">こちら</a>も併せてご確認ください。

２．前回問題を解いた際のカテゴリ選択の記録が残るよう修正しました。

３．直近３回中n回間違えた問題の抽出について、n回『以上』間違えた問題を抽出するよう変更しました。
 `,
          date: '2023/12',
        },
        {
          title: '経営学追加のお知らせ',
          description: `
 Web問題集に新たに2023年目標_経営学の「経営管理 速習講義レジュメ」を追加いたしました。
 カテゴリ選択時に，2023年目標（2023/2024年目標）を選択することで表示されます。

 経営学の問題は以下の流れで単語帳のようにご利用いただける問題集となります。
 是非ご活用ください。

 １．問題選択画面のカテゴリより経営学を選択し、重要度、その他のオプションを選択の上、開始ボタンを押す
 ２．問題を読み、解答を思い浮かべてから「解答を確認する」を押す
 ３．解答を確認し、思い浮かべた解答が合っていたら「正解」、誤っていたら「不正解」を押し、次の問題に進む
 ４．上記１．～３．を繰り返し、全問終了したら結果送信画面から結果を送信する
 `,
          date: '2023/06',
        },
        {
          title: '2024/2025年目標_財務会計論（理論）追加のお知らせ',
          description: `2024/2025年目標_財務会計論（理論）の短答対策問題集を追加いたしました。
カテゴリ選択時に，2024年目標（2024/2025年目標）を選択することで表示されます。
（※）2024年目標（2024/2025年目標）の他の科目の問題集は完成次第，随時配信予定となります。`,
          date: '2023/02',
        },
        {
          title: 'システムメンテナンスのお知らせ',
          description: `11月17日の5時～6時の間でシステムメンテナンスを実施いたします。
メンテナンス時間中にログインされていた場合、強制的にログアウトされる場合がございますので、ご注意ください。

また、ブラウザやパスワードマネージャでパスワードを自動保存していた場合、メンテナンス後は再度ご登録いただく必要がございます。
パスワードをお忘れの場合には、お手数ではございますが、ログイン画面の「パスワードをお忘れですか？」より再登録をお願い致します。
`,
          date: '2022/11',
        },
        {
          title: '「問題画面」、「直近の成績確認」から、メモを追加できるようになりました。',
          description: '「問題画面」のメモアイコンをタップ・クリックすることでメモを残すことができるようになりました。また、「直近の成績確認」で、問題をタップ・クリックし表示される問題情報からもメモを確認・編集できます。',
          date: '2022/10',
        },
        {
          title: '「直近の成績確認」から、問題・解説文を確認できるようになりました。',
          description: '「直近の成績確認」で、問題をタップ・クリックすると問題・解説文が表示されるようになりました。これにより、成績画面から問題文の情報を直接確認することができます。',
          date: '2022/09',
        },
        {
          title: '「まだ解いていない問題」の出題機能を追加しました',
          description: '一問一答問題選択 > その他オプションで「まだ解いていない問題を出題」を選択できるようになりました。 これにより、未回答問題で絞り込むことが可能となります。',
          date: '2022/08',
        },
        {
          title: '「直近3回の正答数」 での出題機能を追加しました',
          description: '一問一答問題選択 > その他オプションで「直近3回でXX回間違えた問題を出題」を選択できるようになりました。 これにより1〜3回までの直近で間違えた回数で出題問題を絞り込むことが可能となります。',
          date: '2022/08',
        },
      ]
    }
  },
  watch: {
    openWhatsNew(val) {
      if (val) {
        this.dialog = val
      }
    },
    dialog(val) {
      this.$emit('update:openWhatsNew', val)
    }
  },
}
</script>

<style scoped>
.small-date-text {
  font-size: 0.4rem;
}

.description {
  white-space: pre-line;
}
</style>
