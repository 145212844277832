import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard";
import store from "../store";
import { getInstance } from "../auth";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'top',
    component: () => import('../pages/SelectQuestions') ,
  },
  {
    path: '/select_questions/:mode',
    component: () => import('../pages/SelectQuestions'),
    props: true,
  },
  {
    path: '/select_questions',
    component: () => import('../pages/SelectQuestions'),
  },
  {
    path: '/question/:page',
    name: 'question',
    component: () => import('../pages/Question'),
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../components/Question/Result')
  },
  {
    path: '/past_results',
    name: 'past_results',
    component: () => import('../pages/PastResult'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../pages/Account'),
    beforeEnter: authGuard,
  },
  {
    path: '*',
    redirect: { name: 'top' }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_CPA_MODE !== '1') return next();

  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Otherwise, log in
    // authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
});


router.afterEach((_to, from, next) => {
  if (from.name !== null) {
    // リロード時は無視する
    store.dispatch('updateExpiredAt');
  }
  next
});

export default router
