"use strict";

import Vue from 'vue';
import axios from "axios";
import { localStorageKey } from "@/store";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
if (process.env.VUE_APP_CPA_MODE === '1') {
  axios.defaults.baseURL = process.env.VUE_APP_CPA_BASE_URL || process.env.VUE_APP_API_BASE_URL;
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
}


let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

axios.interceptors.request.use((config) => {
  const session = window.localStorage[localStorageKey];
  const idToken = session ? JSON.parse(session).users.idToken : null;
  if (idToken) {
    config.headers['Authorization'] = `Bearer ${idToken}`;
    return config
  }
  return config
}, function (error) {
  console.error(error);
  return Promise.reject(error)
});

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      console.error(error);
      return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
